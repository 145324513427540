// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// immagini
const images = require.context('images', true)
  // const imagePath = (name) => images(name, true)

// inizializzatore scss
import "stylesheets/application"

// Fontaswesome
import "@fortawesome/fontawesome-free/js/all"


// Bootstrap Icons
require('bootstrap-icons/font/bootstrap-icons.css');


// // Datatables
// require('datatables.net-bs4')()
// require('datatables.net-buttons-bs4')()
// require('datatables.net-responsive-bs4')()
// require('datatables.net-select-bs4')()

// // Tempus Dominus datetime picker
// require('moment/locale/it')
// require('tempusdominus-bootstrap-4')

// // Bootstrap date picker
// require('bootstrap-datepicker')

// // inizializzatori javascript
// require('init/sweetalert')
// require('init/datatables')
// require('init/tempusdominus')
// require('init/bootstrap-datepicker')

// Swiper
import Swiper from 'swiper/bundle'
window.Swiper = Swiper

// VideoJS

require('video.js')
/*
require('packs/videojs/vimeo.js')
require('packs/videojs/youtube.js')
*/
import videojs from 'video.js';
global.videojs = videojs;

require('packs/videojs/nuevo.min')

require('packs/videojs/lang/it')

// javascript personalizzato
require('packs/custom')

// Javascript template
require('packs/slick.min')
require('packs/main')
require('packs/jquery.progressTimer.min')
require('packs/jquery.autocomplete.min')
require('packs/jquery.bracket')

import lazy from 'jquery-lazy';
global.lazy = lazy;

// Screenshots with JavaScript: https://github.com/niklasvh/html2canvas
import html2canvas from 'html2canvas';
global.html2canvas = html2canvas;

import $ from 'jquery';
global.$ = jQuery;

import './pagy.js'

import Swal from 'sweetalert2'
window.Swal = Swal