// jQuery Plugin BracketGame
(function ($) {

    $.bracketGame = function (element, options) {
        var defaults = {
            badgeMargin: 20,
            lineLeader: false,
        }

        var plugin = this;

        plugin.settings = {}

        var $element = $(element),
            element = element;

        var lines = [];
        var currentPage = 0;
        var maxHeight = 0;

        ////////////////////////////////////
        // public methods
        ////////////////////////////////////

        plugin.init = function () {
            plugin.settings = $.extend({}, defaults, options);

            maxHeight = getFitPageSize(0);
            $element.find(".main-viewport").height(maxHeight + $(".title-wrapper").height() + $element.find('.tbuttons').height());
            $element.find(".main-wrapper").css({ top: $(".title-wrapper").height() + "px" });

            plugin.centerPageInViewport(0, false);

            // LINE-LEADER
            if (plugin.settings.lineLeader) {
                var pages = $element.find('.page');
                $.each(pages, function (i) {
                    var current_page = i;
                    var next_page = current_page + 1;
                    var last_page = pages.length - 1;
                    if (i < last_page) {
                        // collego i badges
                        $.each($(this).find('.bbadge'), function (j) {
                            var badge_counter = j + 1;
                            var next_badge = Math.ceil(badge_counter / 2);
                            var startPoint = document.getElementById($(this).attr('id'));
                            var endPoint = document.getElementById('badge' + next_page + '_' + next_badge);
                            lines.push(new LeaderLine(startPoint, endPoint, {
                                size: 2,
                                startPlug: 'behind',
                                endPlug: 'behind',
                                path: 'grid',
                            }));
                        });
                    }
                });
            } else {
                createLineDivs();
            }

            $element.find(".main-viewport").on('scroll', function () {
                updateLines();
            });
        }

        plugin.centerPageInViewport = function (page_number, animated = true) {
            var wrapper = $element.find(".main-wrapper, .title-wrapper");
            var vp_width = $element.find(".main-viewport").outerWidth();
            var pg_width = $($element.find(".page").find('.bbadge')[0]).outerWidth();
            // var left_pos = (vp_width - pg_width) / 2 - $($element.find(".page")[page_number]).position().left;
            var left_pos = - $($element.find(".page")[page_number]).position().left + 20;
            if (animated) {
                wrapper.animate(
                    {
                        left: left_pos
                    },
                    {
                        duration: 500,
                        step: function () {
                            updateLines();
                        }
                    });
            } else {
                wrapper.css({ left: left_pos });
                updateLines();
            }

            if (page_number < currentPage) {
                resizeBracketAfterDivs(page_number, true);
            }
            if (page_number > currentPage) {
                resizeBracketAfterDivs(page_number - 1, false);
            }
            currentPage = page_number;

            setFitPageSize(page_number, animated);
        }

        plugin.nextPage = function () {
            var pages = $element.find('.page');
            var selectedPage = currentPage < pages.length - 1 ? currentPage + 1 : currentPage
            plugin.centerPageInViewport(selectedPage);
            updateLines();
        }

        plugin.prevPage = function () {
            var selectedPage = currentPage > 0 ? currentPage - 1 : currentPage;
            plugin.centerPageInViewport(selectedPage);
            updateLines();
        }

        plugin.goToPage = function (selectedPage) {
            plugin.centerPageInViewport(selectedPage);
            updateLines();
        }

        plugin.getCurrentPage = function () {
            return currentPage;
        }

        plugin.setNextPageBadge = function ($clickedBadge) {

            var html = $clickedBadge.find('.bbadge_team_cont').html();
            var data_team = $clickedBadge.data('team');
            var pagina = parseInt($clickedBadge.find('.form-check-input').attr('name').split('_')[1]);
            var badge = parseInt($clickedBadge.find('.form-check-input').attr('name').split('_')[2]);

            var $next_page_badge = $($($element.find('.page')[pagina + 1]).find('.bbadge_team')[badge]);

            var isNextBadgeEmpty = $next_page_badge.hasClass('empty');

            $next_page_badge.find('.bbadge_team_cont').html(html);
            $next_page_badge.find('.bbadge_team_cont').hide();
            $next_page_badge.find('.bbadge_team_cont').fadeIn(500);
            $next_page_badge.removeClass('empty');
            $next_page_badge.attr('id', 't_' + (pagina + 1) + '_' + data_team);
            $next_page_badge.data('team', data_team);

            if (!isNextBadgeEmpty && $next_page_badge.find('.form-check-input').is(':checked')) {
                plugin.setNextPageBadge($next_page_badge);
            }
        }

        plugin.generaAlberoJSON = function () {
            var tree = [];
            var page_count = $element.find('.page').length;
            for (var p = 0; p <= page_count - 1; p++) {
                var arr = [];
                $($($element.find('.page')[p]).find('.bbadge')).each(function (i) {
                    var team1 = $($(this).find('.bbadge_team')[0]);
                    var team2 = $($(this).find('.bbadge_team')[1]);
                    var team1_id = team1.data('team');
                    var team2_id = team2.data('team');
                    var team_win = team1.find('.form-check-input').is(':checked') ? team1_id : team2_id;
                    var match = {
                        't1': team1_id,
                        't2': team2_id,
                        'wt': team_win,
                    };
                    arr.push(match);
                });
                tree.push(arr);
            }
            return JSON.stringify(tree);
        }

        plugin.createBracketFromJSON = function (user_choices) {
            if(user_choices != '') {
                var json = JSON.parse(user_choices);
                // console.log(json);
                $.each(json, function (i, page) {
                    // console.log(page);
                    $.each(page, function (j, badge) {
                        // console.log("#t_"+i+"_"+badge.wt);
                        $("#t_" + i + "_" + badge.wt).click();
                        if(typeof badge.winner_taken !== 'undefined') {
                            if(badge.winner_taken) {
                                $("#t_" + i + "_" + badge.wt).addClass("right_choice");
                            } else {
                                $("#t_" + i + "_" + badge.wt).addClass("wrong_choice");
                            }
                        }
                    });
                });
                var last_page = json.length-1;
                if(typeof json[last_page][0].winner_taken !== 'undefined') {
                    // console.log(json[last_page].winner_taken);
                    if(json[last_page][0].winner_taken) {
                        $(".winner_badge").addClass('right_choice');
                    } else {
                        $(".winner_badge").addClass('wrong_choice');
                    }
                }
            }
        }

        plugin.destroy = function () {
            $element.find('.page').each(function(i) {
                $(this).css({ 'height': 'auto', 'margin-top': '0px' });
                if($(this).find('.bracket-after').length > 0) {
                    $(this).find('.bracket-after').remove();
                }
                if($(this).find('.bracket-after-after').length > 0) {
                    $(this).find('.bracket-after-after').remove();
                }
                if(i > 0) {
                    $(this).find('.bbadge_team').addClass('empty');
                    $(this).find('.bbadge_team_cont').html('');
                }
            });
            $element.find('.bbadge_team').removeClass('active');
            $element.find('.form-check-input').prop('checked', false);
            $element.find('.winner_badge_img img').attr('src', '');
            $element.find('.winner_badge h1').html('');
            $element.find('.winner_badge').css({ 'height': '0px', 'opacity': 0 });
            $element.find('.winner_page_before').css('width', '0px');
        }

        ////////////////////////////////////
        // private methods
        ////////////////////////////////////

        var createLineDivs = function () {
            var pages = $element.find('.page');
            pages.each(function (j) {

                $(this).append("<div class='bracket-after'></div>");
                $(this).append("<div class='bracket-after-after'></div>");
                if (j < pages.length - 1) {
                    for (var i = 0; i < $(this).find('.bbadge').length / 2; i++) {
                        $(this).find('.bracket-after').append('<div></div>');
                        var baa_class = i < $(this).find('.bbadge').length / 4 ? '' : 'from_bottom';
                        $(this).find('.bracket-after-after').append('<div><div class="' + baa_class + '"></div></div>');
                    }
                }
                var n_badges = $(this).find('.bbadge').length;
                var br_after_height_perc = 100 / n_badges;
                // $(this).find('.bracket-after > div').height(br_after_height_perc+'%');
                $(this).find('.bracket-after > div').css({ height: br_after_height_perc + '%' });
                $(this).find('.bracket-after-after > div').each(function (i) {
                    var br_after_top_perc = br_after_height_perc + (br_after_height_perc * (i * 2));
                    $(this).css({ top: br_after_top_perc + '%' });
                });

            });

        }

        var resizeBracketAfterDivs = function (page_number, previous = false) {

            var page = $($element.find('.page')[page_number]);
            var page_height = getFitPageSize(page_number);
            var mod_size = page_height / page.find('.bbadge').length;
            var mtop = (maxHeight - getFitPageSize(page_number + 1)) / 2;
            page.find(".bracket-after-after > div").each(function (i) {

                if (previous) {
                    dh = 0; // reset
                } else {
                    var el_pos = $(this).position().top;
                    var half_page = (page_height / 2)
                    var delta = half_page > el_pos ? half_page - el_pos : el_pos - half_page;
                    var delta_mod = (i + 1) - (page.find(".bracket-after-after > div").length / 2);
                    delta_mod = delta_mod <= 0 ? Math.abs(delta_mod) + 1 : delta_mod;

                    dh = delta_mod * mod_size - mod_size / 2;
                }

                var el = $($(this).find('> div'));
                el.animate(
                    {
                        height: dh
                    },
                    {
                        duration: 500
                    });
            });
        }

        var updateLines = function () {
            if (plugin.settings.lineLeader) {
                lines.forEach(element => {
                    element.position();
                });
            }
        }

        var getFitPageSize = function (page_number) {
            var page = $($element.find('.page')[page_number]);
            var badge_height = $(page.find('.bbadge')[0]).outerHeight();
            // var page_height = (page.find('.bbadge').length * (badge_height + plugin.settings.badgeMargin)) - plugin.settings.badgeMargin;
            var page_height = (page.find('.bbadge').length * (badge_height + plugin.settings.badgeMargin));

            return page_height;
        }

        var setFitPageSize = function (page_number, animated = true) {
            var page_height = getFitPageSize(page_number);
            var top_pos = (maxHeight - page_height) / 2;
            top_pos = top_pos < 0 ? 0 : top_pos;
            $.each($element.find('.page'), function (i) {
                if (i >= page_number) {
                    if (animated) {
                        $(this).animate(
                            {
                                height: page_height,
                                marginTop: top_pos,
                            },
                            {
                                duration: 500,
                                step: function () {
                                    updateLines();
                                }
                            });
                    } else {
                        $(this).height(page_height);
                        updateLines();
                    }
                }
            });
            scrollToFitPage(page_number);
        }

        var scrollToFitPage = function (page_number) {
            var page_height = getFitPageSize(page_number);
            var vp_height = $element.find(".main-viewport").outerHeight();
            var top_scroll = 0;
            if (maxHeight > vp_height && page_height < vp_height) {
                top_scroll = (maxHeight - vp_height) / 2;
            }
            // console.log(top_scroll);
            $element.find(".main-viewport").stop().animate({ scrollTop: top_scroll }, 500);
        }

        // INIT PLUGIN
        plugin.init();

        $(window).on('resize', function () {
            plugin.centerPageInViewport(currentPage, false);
        });
    }

    $.fn.bracketGame = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('bracketGame')) {
                var plugin = new $.bracketGame(this, options);
                $(this).data('bracketGame', plugin);
            }
        });
    }

})(jQuery);