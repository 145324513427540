var cycle = null;

$(document).on('ready turbolinks:load', function() {
  // check for socket connections
  checkSocketConnections();

  // video-js reload
  reinitVideos();

  // SECOND LEVEL MENU TRIGGERS
  $(".lba-nav .sub-menu-item").hover(function() {
    var mId = $(this).data('id');
    console.log("mId: ", mId);

    var navbarLeftPosition = $('#lev2-nav-' + mId).parent().parent().offset().left;
    var navbarWidth = $('.lba-nav').width()

    $(".lba-nav ul.sub-menu").addClass('d-none');
    $(".lba-nav ul.sub-menu").removeClass('d-lg-flex');
    $(".lba-nav .sub-menu-item").removeClass('text-secondary');

    $('#lev2-nav-' + mId).css('left', navbarLeftPosition);
    $('#lev2-nav-' + mId).width(navbarWidth);
    
    $(this).addClass('text-secondary');

    $('#lev2-nav-' + mId).removeClass('d-none');
    $('#lev2-nav-' + mId).addClass('d-lg-flex');
    $('#lev2-nav-' + mId).addClass('text-secondary');
  });

  // toggle della password
  $('.togglePassword').on('click', function() {
    icon_svg = $(this).find('svg')
    if (icon_svg.attr('data-icon') === 'eye') {
      icon_svg.attr('data-icon', 'eye-slash')
    } else {
      icon_svg.attr('data-icon', 'eye')
    }

    input = $($(this).data('toggle'))
    if (input.attr('type') == 'password') {
      input.attr('type', 'text')
    } else {
      input.attr('type', 'password')
    }
  })

  $('body').on('cut copy', function(e) {
    if ($('.lba-originals').length) {
      e.preventDefault();
    }
  });

  function openMegaMenu()
  {
    if(!$("body").hasClass("mobile")) {
      $('.megamenu').fadeIn();
    }
    $(".megamenu").addClass("open");
    $("body").addClass("menu-open");
    $('#menu-toggler .bi-three-dots').hide();
    $('#menu-toggler .bi-x-circle-fill').show();
    $("#menu-nav-toggle").addClass('open');
  }

  function closeMegaMenu()
  {
    if(!$("body").hasClass("mobile")) {
      $('.megamenu').fadeOut();
    }
    $(".megamenu").removeClass("open");
    $("body").removeClass("menu-open");
    $('#menu-toggler .bi-three-dots').show();
    $('#menu-toggler .bi-x-circle-fill').hide();
    $("#menu-nav-toggle").removeClass('open');
  }

  // MEGA MENU
  $('#menu-toggler').on('click', function(event) {
    event.preventDefault();
    if(!$('.megamenu').hasClass('open')) {
      openMegaMenu();
    } else {
      closeMegaMenu();
    }
  });

  // MOBILE MEGA MENU
  $("#mobile-megamenu-toggler").on('click', function(e)
  {
    e.preventDefault();
    $("#mob-menu").removeClass("open")
    openMegaMenu();    
  });

  // hamburger icon/menu
  $("#menu-nav-toggle").on('click', function() {
    
    if (!$("#menu-nav-toggle").hasClass("open")) {
      $("#mob-menu, #menu-nav-toggle").addClass("open");
      $("body").addClass("menu-open");
      $('#lba-header-logo').removeClass('smaller');
    } else {
      $("#mob-menu, #menu-nav-toggle, .megamenu").removeClass("open");
      $("body").removeClass("menu-open");
      closeMegaMenu();
    }
  });

  $("#mob-menu .lba-nav .nav-link, .megamenu a").on('click', function() {
    $("#mob-menu, #menu-nav-toggle").removeClass("open");
    $("body").removeClass("menu-open");
    closeMegaMenu();
  });

  // LOGO
  window.onscroll = function() { scrollFunction(); };
  scrollFunction();

  function scrollFunction() {
    var logo = $('#lba-header-logo');
    if ($(window).scrollTop() > $("header").outerHeight()*2) {
      logo.addClass('smaller');
    } else {
      logo.removeClass('smaller');
    }

    /*
    if($(".player-main-tabs-wrapper").length > 0){
      console.log('fixed');
      if($(window).scrollTop() >= $(".player-main-tabs").offset().top - $("header.fixed-top").height()){
        // $(".player-main-tabs-wrapper").addClass("fixed");
        // $(".player-main-tabs-wrapper").css('top', $("header.fixed-top").height()+'px');
      } else {
        $(".player-main-tabs-wrapper").removeClass("fixed");
      }
    }
    */
  }

  // HOME CAROUSELS BS
  var globalInterval = 5000;
  var slideCounter = 0;

  if ($('#main-carousel-bg').length > 0) {
    var mainCarousel = new bootstrap.Carousel(document.querySelector('#main-carousel-bg'), {
      interval: false,
      pause: false,
      ride: true,
      wrap: true,
      touch: false,
    });
    var titleCarousel = new bootstrap.Carousel(document.querySelector('#main-carousel-titles'), {
      interval: globalInterval,
      pause: false,
      ride: true,
      wrap: true,
      touch: false,
    });

    $('.main-carousel .carousel-indicators li').off('click').on('click', function() {
      goToSlide($(this).data('slideTo'));
    });

    goToSlide(slideCounter);
    slideCounter++;
  }

  function goToSlide(n) {
    clearInterval(cycle);
    slideCounter = n;
    mainCarousel.to(slideCounter);
    titleCarousel.to(slideCounter);
    $('div[id^=progress-timer]').progressTimer({
      timeLimit: 0
    });
    $('.main-carousel .carousel-indicators li').removeClass('active');
    $($('.main-carousel .carousel-indicators li')[slideCounter]).addClass('active');
    $('#progress-timer-' + slideCounter).progressTimer({
      timeLimit: globalInterval / 1000
    });
    mainCarousel.pause();
    titleCarousel.pause();
    cycleCreate();
  }

  function cycleCreate() {
    var nextSlideCounter = slideCounter < $('.main-carousel .carousel-indicators li').length - 1 ? slideCounter + 1 : 0;
    cycle = setInterval(function() {
      goToSlide(nextSlideCounter);
      slideCounter = nextSlideCounter;
    }, globalInterval);
  } 


  
  // HOME MATCH PREVIEW
  if ($('.home-page-match-preview').length > 0) {
    new Swiper('.home-page-match-preview', {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next.match-preview-next',
        prevEl: '.swiper-button-prev.match-preview-prev',
      },
      pagination: {
        el: '.swiper-pagination.match-preview-pagination',
        clickable: true,
      }
    });
  }


  // HOME GALLERY
  if ($('.home-page-gallery').length > 0) {
    new Swiper('.home-page-gallery', {
      // slidesPerView: 3,
      // spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        480: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }


  // HOME PAGE GAME
  if ($('.home-page-games').length > 0) {
    new Swiper('.home-page-games', {
      // slidesPerView: 3,
      // spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1,
      spaceBetween: 15,
    });
  }

  // HOME SLICK
  if ($('.video-carousel').length > 0) {
    $('.video-carousel.slick-initialized').slick('unslick');
    $('.video-carousel').slick({
      infinite: false,
      prevArrow: '<div class="slick-prev"><i class="bi bi-chevron-left"></i></div>',
      nextArrow: '<div class="slick-next"><i class="bi bi-chevron-right"></i></div>',
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }

  if ($('.highlight-carousel').length > 0) {
    $('.highlight-carousel.slick-initialized').slick('unslick');
    $('.highlight-carousel').slick({
      infinite: false,
      prevArrow: '<div class="slick-prev"><i class="bi bi-chevron-left"></i></div>',
      nextArrow: '<div class="slick-next"><i class="bi bi-chevron-right"></i></div>',
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }

  $(".lazy").lazy({
    scrollDirection: 'vertical',
    effect: "fadeIn",
    effectTime: 500,
    threshold: 0,
    onError: function(element) {
      console.log('error loading ' + element.data('src'));
    }
  });

  if($('[data-bs-toggle="popover"]').length > 0) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    });
  }

  // form validation
  (function () {
    'use strict'

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          form.classList.add('was-validated')
        }, false);
      });
  })();

  // Remote form submit -> Loading spinner...
  $('.data-remote').on('submit', function (e) {
    $('.loading-data-remote').show();
  });

  $('.select-tabs').on('change', function() {
    $("#" + this.value + "-tab").trigger("click");
  });
  $('.select-links').on('change', function() {
    window.location = this.value;
  });

});

$(document).on('turbolinks:before-cache turbolinks:before-render', function() {
  clearInterval(cycle);
  if ($('.video-carousel.slick-initialized').length > 0) { $('.video-carousel.slick-initialized').slick('unslick'); }
  if ($('.game-selection-carousel.slick-initialized').length > 0) { $('.game-selection-carousel.slick-initialized').slick('unslick'); }
  if ($('#bracketGame').length > 0) { $("#bracketGame").data('bracketGame').destroy(); }
});

function reinitVideos() {
  var player, _i, _len, _ref;
  _ref = document.querySelectorAll('.vjs-tech:not(.lbatv-player), .video-js:not(.lbatv-player)');
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    player = _ref[_i];
    videojs(player, {
      controls: true,
      autoplay: false,
      preload: "auto"
    });
  }
}

function checkSocketConnections() {
  if(typeof websocket !== 'undefined') {
    if($('.play-by-play-wrapper').length == 0) {
      // socket attivo, ma PBP non presente
      websocket.close(1000);
      websocket = undefined;
    }
  }
}

$(window).on('resize', function() {
  checkDevice();
}); 


// TIMER PLUGIN
(function( $ ) {
  var getTimerMethods = {
    init : function(options) {
      settings = $.extend({
      }, options );
      var sections = options.json.replace(/=>/g, ":");
      json = JSON.parse(sections);
      is_playing = false;
      var total_time = Number(json[json.length-1].time);
      var sections_time = 0;
      $.each(json, function(i, val){
        var prev_time = i > 0 ? Number(json[i-1].time) : 0;
        json[i].duration = Number(val.time) - prev_time;
      });
      this.html('');
      for(i=json.length-1;i>=0;i--) {
        json[i].width = (Number(json[i].duration)/total_time)*100;
        this.append("<div style='width:"+json[i].width+"%;' class='section_"+i+"'><span>X"+json[i].factor+"</span><div><div></div></div></div>");
        this.find(".section_"+i).data('width', 100);
      }
      var current = settings.current !== undefined ? settings.current : total_time;
      this.data('current', current);
      this.data('total', total_time);
      this.gameTimer('update');
      if(current > 0) {
        this.gameTimer('play');
      }
      return this;
    },
    update : function( ) {
      var current_time = this.data('current');
      var total_time = this.data('total');
      if(settings.watch != '') {
        minutes = parseInt(current_time / 60, 10);
        minutes = minutes.toString().length < 2 ? '0'+minutes.toString() : minutes.toString();
        seconds = parseInt(current_time % 60, 10);
        seconds = seconds.toString().length < 2 ? '0'+seconds.toString() : seconds.toString();
        var watch = settings.watch;
        watch.html(minutes+":"+seconds);
      }
      this.data('current', current_time-1);
      if(current_time <= 0) {
        this.gameTimer('setTime', 0);
        this.gameTimer('stop');
        this.children("div").find(" > div > div").css("width", "0%");
        $(this.children("div").find(" > div > div")[0]).css("width", "100%");
        return false;
      }
      var current_section = -1;
      var time_passed = total_time - current_time;
      var i=0;
      var section_limit = 0;
      while(current_section < 0 && json[i].time !== undefined) {
        if(time_passed <= Number(json[i].time)) {
          current_section = i;
        }
        i++;
      }
      for(i=0;i<json.length;i++) {
        if(i < current_section) {
          this.children(".section_"+i).data('width', 0);
          this.children(".section_"+i).find(" > div > div").css("width", "0%");
        }
        if(i > current_section) {
          this.children(".section_"+i).data('width', 100);
          this.children(".section_"+i).find(" > div > div").css("width", "100%");
        }
      }
      var step_per_second = 100/Number(json[current_section].duration);
      var prev_time = current_section > 0 ? Number(json[current_section-1].time) : 0;
      var time_passed_in_section = Number(json[current_section].duration) - (time_passed - prev_time);
      var section_el = this.children(".section_"+current_section);
      // console.log(current_section);
      var section_width = section_el.data('width');
      section_width = step_per_second * time_passed_in_section;
      section_width = current_section == json.length-1 ? 100 : section_width;
      // console.log(section_width);
      section_el.data('width', section_width);
      section_el.find("> div > div").css("width", section_el.data('width')+"%");
    },
    play : function() {
      var gt = this;
      is_playing = true;
      timerInterval = setInterval(function(){
        gt.gameTimer('update');
      }, 1000);
    },
    stop : function() {
      if(is_playing) {
        clearInterval(timerInterval);
      }
    },
    setTime : function( seconds ) {
      this.gameTimer('stop');
      this.data('current', seconds);
      this.gameTimer('play');
    }
  };
  $.fn.gameTimer = function(methodOrOptions) {
    if ( getTimerMethods[methodOrOptions] ) {
        return getTimerMethods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
        // Default to "init"
        return getTimerMethods.init.apply( this, arguments );
    } else {
        $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.gameTimer' );
    }          
  };
})(jQuery);

var userHeaderIds = ['user_login_header', 'user_info_header', 'user_press_header'];
jQuery.fn.saveUserHeaders = function () {
  if (typeof localStorage === "undefined") { return; }

  console.debug('saveUserHeaders');
  userHeaderIds.forEach(function (id) {
    var el = document.getElementById(id);
    if (el) { localStorage[id] = el.outerHTML; }
  });
  localStorage.user_header_saved_at = new Date().getTime();
}
jQuery.fn.cleanUserHeaders = function () {
  if (typeof localStorage === "undefined") { return; }

  console.debug('cleanUserHeaders');
  delete localStorage.user_header_saved_at;
  userHeaderIds.forEach(function (id) {
    delete localStorage[id];
  });
}
jQuery.fn.restoreUserHeaders = function () {
  if (typeof localStorage === "undefined") { return; }

  var TAG = 'restoreUserHeaders';
  var expireSeconds = 30 * 60; // Stesso valore di Devise session_timeout
  var savedAt = parseInt(localStorage.user_header_saved_at);

  if (!savedAt) {
    console.debug(TAG, 'no saved data');
    return;
  }

  if (new Date().getTime() / 1000 > savedAt / 1000 + expireSeconds) {
    jQuery.fn.cleanUserHeaders();
  } else {
    if($('#footer-sign-in-up')) { $('#footer-sign-in-up').hide(); }
    console.debug(TAG, 'restore cached data');
    userHeaderIds.forEach(function (id) {
      var el = document.getElementById(id);
      if (localStorage[id] && el) { el.outerHTML = localStorage[id]; }
    });
  }
}

// HELPERS
String.prototype.replaceInTemplate = function (find, replace) {
  var replaceString = this;
  var regex;
  for (var i = 0; i < find.length; i++) {
    regex = new RegExp("{{" + find[i] + "}}", "g");
    replaceString = replaceString.replace(regex, replace[i]);
  }
  return replaceString;
};