videojs.addLanguage("it",{
  "Play": "Play",
  "Pause": "Pausa",
  "Current Time": "Orario attuale",
  "Duration": "Durata",
  "Remaining Time": "Tempo rimanente",
  "Stream Type": "Tipo del Streaming",
  "LIVE": "LIVE",
  "Loaded": "Caricato",
  "Progress": "Stato",
  "Fullscreen": "Schermo intero",
  "Non-Fullscreen": "Chiudi schermo intero",
  "Mute": "Muto",
  "Unmute": "Audio",
  "Playback Rate": "Tasso di riproduzione",
  "Subtitles": "Sottotitoli",
  "subtitles off": "Senza sottotitoli",
  "Captions": "Sottotitoli non udenti",
  "captions off": "Senza sottotitoli non udenti",
  "Chapters": "Capitolo",
  "You aborted the media playback": "La riproduzione del filmato è stata interrotta.",
  "A network error caused the media download to fail part-way.": "Il download del filmato è stato interrotto a causa di un problema rete.",
  "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Il filmato non può essere caricato a causa di un errore nel server o nella rete o perché il formato non viene supportato.",
  "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "La riproduzione del filmato è stata interrotta a causa di un file danneggiato o per l’utilizzo di impostazioni non supportate dal browser.",
  "No compatible source was found for this media.": "Non ci sono fonti compatibili per questo filmato.",
  "Exit Picture-in-Picture": "Esci da Picture-in-Picture",
  "Picture-in-Picture": "Picture-in-Picture",
  "Quality": "Qualità",
  "Settings": "Impostazioni",
  "Related": "Related",
  "Share": "Condividi",
  "Speed": "Velocità",
  "Zoom:": "Zoom",
  "Link": "Link",
  "Embed": "Embed",
  "Social": "Social",
  "Check out this cool video on": "Check out this cool video on",
  "RESET": "RESET",
  "Zoom": "Zoom",
  "ZOOM HELP": "ZOOM HELP",
  "Use ZOOM slider or mouse wheel to ZOOM in video.": "Use ZOOM slider or mouse wheel to ZOOM in video.",
  "Drag zoomed area using your mouse or a finger.": "Drag zoomed area using your mouse or a finger.",
  "Forward": "Forward",
  "Rewind":  "Rewind",
  "Mirror view": "Mirror view",
  "Theater mode": "Theater mode",
  "Advertisement": "Inserzione",
  "Skip Ad in": "Salta inserzione in",
  "Skip Now!": "Salta ora!",
  "Watch full video on": "Watch full video on" ,
  "Casting to": "Casting to",
  "Skip in %%TIME%% seconds": "Salta in %%TIME%% secondi",
  "Skip ad": "Salta inserzione",
  "Snapshot": "Snapshot"
});